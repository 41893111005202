import {
  IsBoolean,
  IsDateString,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Max,
  Min,
} from 'class-validator';

export class CarWashFormSchema {
  @IsNotEmpty({ message: 'Выберите авто' })
  @IsNumber({}, { message: 'Выберите авто' })
  carId: number;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  mileage: number | null;

  @IsOptional()
  @IsBoolean()
  updateMileage: boolean | null;

  @IsNotEmpty()
  @IsDateString({}, { message: 'Укажите дату в формате 2024-12-23' })
  date: string;

  @IsOptional()
  place: string | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  cost: number | null;

  @IsOptional()
  notes: string | null;

  @IsOptional()
  fileUrls: string[];
}
