import React, { useEffect, useState } from 'react';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { cleanObjectByValues } from '../../utils/cleanObject';
import { useAccessToken } from '../../hooks/useAccessToken';
import { ParkPaymentFormSchema } from './schemas/ParkPaymentFormSchema';
import { Select, SelectOption } from '../Select/Select';
import { useFetch } from '../../hooks/useFetch';
import { FileLoader } from '../FileLoader/FileLoader';
import { MessageDialogModal } from '../Modal/MessageDialogModal';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';
import {
  CarEntity,
  CreateParkPaymentDto,
  ParkPaymentEntity,
  UpdateParkPaymentDto,
  UserEntity,
} from '../../clients/VgarageApi/types.gen';

import {
  carControllerSearchCar,
  carControllerUpdateCar,
  parkPaymentControllerCreate,
  parkPaymentControllerRemove,
  parkPaymentControllerUpdate,
  userControllerGetCurrentUser,
} from '../../clients/VgarageApi/sdk.gen';
import { ConsumableType } from '../../types/ConsumableType';
import { Hint } from '../Hint/Hint';

export interface onCloseParkPaymentFormOptions {
  parkPayment?: ParkPaymentEntity;
  text?: string;
}

export interface ParkPaymentFormProps {
  parkPayment?: ParkPaymentEntity | null;
  defaultValues?: Partial<ParkPaymentFormSchema> | undefined;
  closeButtonText?: React.ReactNode | undefined;
  selectedConsumableType?: ConsumableType | null;
  setSelectedConsumableType: React.Dispatch<ConsumableType>;
  consumableTypeSelectOptions: SelectOption<string>[];
  onClose: (options?: onCloseParkPaymentFormOptions) => void;
  setSelectedCarId?: React.Dispatch<number>;
}

export const ParkPaymentForm = ({
  parkPayment = null,
  defaultValues = undefined,
  closeButtonText,
  onClose,
  selectedConsumableType,
  setSelectedConsumableType,
  consumableTypeSelectOptions,
  setSelectedCarId,
}: ParkPaymentFormProps) => {
  const { accessToken } = useAccessToken();

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    if (accessToken) {
      const result = await userControllerGetCurrentUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data || null;
    } else {
      return null;
    }
  });

  useEffect(() => {
    fetchUser();
  }, [accessToken]);

  const [fetchCars, isFetchingCars, carsError, cars] = useFetch<CarEntity[] | null>(async () => {
    if (user) {
      const result = await carControllerSearchCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        query: {
          userId: user.id,
        },
      });

      return result.data!;
    }

    return null;
  });

  useEffect(() => {
    fetchCars();
  }, [accessToken, user]);

  const [carsSelectOptions, setCarsSelectOptions] = useState<null | SelectOption<number>[]>(null);

  useEffect(() => {
    const selectOptions = cars?.map((car) => ({
      value: car.id,
      text: car.brand.name + ' ' + car.model.name,
    }));

    setCarsSelectOptions(selectOptions || null);
    console.log(carsSelectOptions);
  }, [cars]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(ParkPaymentFormSchema);

  const {
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty, dirtyFields },
  } = useForm<ParkPaymentFormSchema>({
    resolver: formResolver,
    defaultValues: parkPayment
      ? {
          ...parkPayment,
          carId: parkPayment.car?.id || undefined,
          fileUrls: parkPayment.fileNames || undefined,
          date: parkPayment.date?.split('T')[0] || undefined,
          ...(defaultValues || {}),
        }
      : { ...defaultValues },
  });

  const onValid: SubmitHandler<ParkPaymentFormSchema> = async (data) => {
    let cleanedData = cleanObjectByValues(data, [undefined]);

    console.log(cleanedData);

    if (!parkPayment?.id) {
      const result = await parkPaymentControllerCreate({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: cleanedData as CreateParkPaymentDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 201) {
        setFormHintClass('form__hint--green');
        onClose({
          parkPayment: result.data!,
          text: 'Оплата парковки успешно добавлена',
        });
      } else {
        setFormHintClass('form__hint--red');
        const errorObj = result.error as any;
        const errorMessage =
          errorObj.statusCode === 500 ? errorObj.message : errorObj.message?.join(', ');
        setFormHintMessage(errorMessage);
      }
    } else {
      const result = await parkPaymentControllerUpdate({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: parkPayment?.id.toString(),
        },
        body: cleanedData as UpdateParkPaymentDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 200) {
        setFormHintClass('form__hint--green');
        onClose({
          parkPayment: result.data!,
          text: 'Оплата парковки успешно обновлена',
        });
      } else {
        setFormHintClass('form__hint--red');
        const errorObj = result.error as any;
        const errorMessage =
          errorObj.statusCode === 500 ? errorObj.message : errorObj.message?.join(', ');
        setFormHintMessage(errorMessage);
      }
    }

    // ---

    if (data.updateMileage && data.mileage) {
      const result = await carControllerUpdateCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: cleanedData.carId,
        },
        body: {
          mileage: data.mileage!,
        },
      });

      console.log('Обновление пробега:', result);
    }
  };

  const onInvalid: SubmitErrorHandler<ParkPaymentFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await parkPaymentControllerRemove({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: parkPayment!.id.toString(),
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose({ text: 'Оплата парковки удалена' });
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  const onClickClose = () => {
    {
      if (formIsDirty && Object.keys(dirtyFields).length) {
        showDialog({
          text: 'Изменения не сохранены',
          yesButtonText: (
            <>
              сохранить и<br />
              перейти
            </>
          ),
          noButtonText: (
            <>
              перейти без
              <br />
              сохранения
            </>
          ),
          onClickYes: () => {
            hideDialog();
            onSubmit();
          },
          onClickNo: () => {
            hideDialog();
            onClose();
          },
        });
      } else {
        onClose();
      }
    }
  };

  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form__header">
          <span className="form__header-title">Добавить оплату парковки</span>
          <span className="form__header-button button-go-back" onClick={() => onClickClose()}>
            <svg
              className="button-go-back-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
            </svg>
            <span>{closeButtonText}</span>
          </span>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите авто</label>
          <div className="form__item-element">
            <Controller
              name="carId"
              control={control}
              render={({ field }) => (
                <Select
                  options={carsSelectOptions}
                  defaultValue={field.value}
                  onChange={(value) => {
                    field.onChange();
                    if (setSelectedCarId) setSelectedCarId(value as number);
                  }}
                  notAllowed={parkPayment?.id !== undefined}
                />
              )}
            />
          </div>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите категорию</label>
          <div className="form__item-element">
            <Select
              options={consumableTypeSelectOptions}
              defaultValue={selectedConsumableType}
              onChange={(value) => {
                console.log(value);
                setSelectedConsumableType(value as ConsumableType);
              }}
              notAllowed={parkPayment?.id !== undefined}
            />
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Пробег, км</label>
          <input
            type="number"
            {...register('mileage', {
              setValueAs: (value) => {
                return value ? parseInt(value) : null;
              },
            })}
          />
        </div>

        <div className="form__item form__item--row">
          <label className="checkbox checkbox__box--light">
            <input type="checkbox" {...register('updateMileage')} />
            <span className="checkbox__box"></span>
            <span className="checkbox__text">Обновить пробег</span>
          </label>
          <Hint
            text={`
                  После сохранения у выбранного автомобиля обновится пробег на указанное значение
                  `}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Дата</label>
          <input style={{ width: '100%' }} type="date" {...register('date')} />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Место</label>
          <input
            {...register('place', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Стоимость, руб</label>
          <input
            type="number"
            step="0.01"
            {...register('cost', {
              setValueAs: (value) => (value ? parseFloat(value) : null),
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="notes">Заметки</label>
          <textarea
            {...register('notes', {
              setValueAs: (value) => value || null,
            })}
          ></textarea>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Документы</label>
          <div className="form__item-element">
            <Controller
              name="fileUrls"
              control={control}
              render={({ field }) => <FileLoader images={field.value} onChange={field.onChange} />}
            />
          </div>
        </div>

        <hr className="separator separator--empty" />

        <button type="submit" className="button--primary">
          Сохранить
        </button>

        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>

        {parkPayment && (
          <button
            onClick={() => {
              showDialog({
                text: 'Удалить оплату парковки?',
                yesButtonText: 'ДА',
                noButtonText: 'НЕТ',
                onClickYes: () => onRemove(),
                onClickNo: () => hideDialog(),
              });
            }}
            className="button button--light"
            type="button"
          >
            <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
            удалить
          </button>
        )}
      </form>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
        }}
      />

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
