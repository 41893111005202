import React, { useEffect, useState } from 'react';
import { ConsumableType, getConsumableTypeSelectOptions } from '../../types/ConsumableType';
import { Select, SelectOption } from '../Select/Select';
import { renderIf } from '../../utils/renderIf';
import { ConsumableEntity } from '../ConnsumablesList/ConsumablesList';
import { MaintenanceForm } from './MintenanceForm';
import {
  carControllerSearchCar,
  CarEntity,
  CarWashEntity,
  FinesPaymentEntity,
  MaintenanceEntity,
  OtherConsumableEntity,
  ParkPaymentEntity,
  RefuelEntity,
  userControllerGetCurrentUser,
  UserEntity,
} from '../../clients/VgarageApi';
import { useFetch } from '../../hooks/useFetch';
import { useAccessToken } from '../../hooks/useAccessToken';
import { CarWashForm } from './CarWashForm';
import { RefuelForm } from './refuelForm';
import { FinesPaymentForm } from './FinesPaymentForm';
import { ParkPaymentForm } from './ParkPaymentForm';
import { OtherConsumableForm } from './OtherConsumableForm';

export interface onCloseConsumableFormOptions {
  consumable?: ConsumableEntity;
  text?: string;
}

export interface ConsumableFormProps {
  defaultValues?: { type?: ConsumableType; carId: number | undefined } | undefined;
  consumable?: ConsumableEntity | null;
  onClose: (options?: onCloseConsumableFormOptions) => void;
  closeButtonText?: React.ReactNode | undefined;
}

export const ConsumableForm = ({
  consumable = null,
  defaultValues = undefined,
  closeButtonText,
  onClose,
}: ConsumableFormProps) => {
  const [consumableType, setConsumableType] = useState<ConsumableType | null>(
    ((consumable?.type || defaultValues) as ConsumableType) || ConsumableType.REFUEL,
  );

  const { accessToken } = useAccessToken();

  useEffect(() => {
    setConsumableType(
      ((consumable?.type || defaultValues?.type) as ConsumableType) || ConsumableType.REFUEL,
    );
  }, [consumable, defaultValues]);

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    if (accessToken) {
      const result = await userControllerGetCurrentUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data || null;
    } else {
      return null;
    }
  });

  useEffect(() => {
    fetchUser();
  }, [accessToken]);

  const [fetchCars, isFetchingCars, carsError, cars] = useFetch<CarEntity[] | null>(async () => {
    if (user) {
      const result = await carControllerSearchCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        query: {
          userId: user.id,
        },
      });

      return result.data!;
    }

    return null;
  });

  useEffect(() => {
    fetchCars();
  }, [accessToken, user]);

  useEffect(() => {
    const carsSelectOptions = cars?.map((car) => ({
      value: car.id,
      text: car.brand.name + ' ' + car.model.name,
    }));

    setCarsSelectOptions(carsSelectOptions || null);
  }, [cars]);

  const [carsSelectOptions, setCarsSelectOptions] = useState<null | SelectOption<number>[]>(null);

  const ConsumableTypeSelectOptions = getConsumableTypeSelectOptions() as SelectOption<string>[];

  const [selectedCarId, setSelectedCarId] = useState<number | null>(null);

  return (
    <>
      {renderIf(
        () => [
          <MaintenanceForm
            defaultValues={selectedCarId ? { carId: selectedCarId } : undefined}
            maintenance={consumable as MaintenanceEntity}
            onClose={(options) =>
              onClose({ ...options, consumable: options?.maintenance || undefined })
            }
            closeButtonText={closeButtonText}
            selectedConsumableType={consumableType}
            setSelectedConsumableType={setConsumableType}
            consumableTypeSelectOptions={ConsumableTypeSelectOptions}
            setSelectedCarId={setSelectedCarId}
          />,
        ],
        consumableType === ConsumableType.MAINTENANCE,
      )}

      {renderIf(
        () => [
          <CarWashForm
            defaultValues={selectedCarId ? { carId: selectedCarId } : undefined}
            carWash={consumable as CarWashEntity}
            onClose={(options) =>
              onClose({ ...options, consumable: options?.carWash || undefined })
            }
            closeButtonText={closeButtonText}
            selectedConsumableType={consumableType}
            setSelectedConsumableType={setConsumableType}
            consumableTypeSelectOptions={ConsumableTypeSelectOptions}
            setSelectedCarId={setSelectedCarId}
          />,
        ],
        consumableType === ConsumableType.CAR_WASH,
      )}

      {renderIf(
        () => [
          <RefuelForm
            defaultValues={selectedCarId ? { carId: selectedCarId } : undefined}
            refuel={consumable as RefuelEntity}
            onClose={(options) => onClose({ ...options, consumable: options?.refuel || undefined })}
            closeButtonText={closeButtonText}
            selectedConsumableType={consumableType}
            setSelectedConsumableType={setConsumableType}
            consumableTypeSelectOptions={ConsumableTypeSelectOptions}
            setSelectedCarId={setSelectedCarId}
          />,
        ],
        consumableType === ConsumableType.REFUEL,
      )}

      {renderIf(
        () => [
          <FinesPaymentForm
            defaultValues={selectedCarId ? { carId: selectedCarId } : undefined}
            finesPayment={consumable as FinesPaymentEntity}
            onClose={(options) =>
              onClose({ ...options, consumable: options?.finesPayment || undefined })
            }
            closeButtonText={closeButtonText}
            selectedConsumableType={consumableType}
            setSelectedConsumableType={setConsumableType}
            consumableTypeSelectOptions={ConsumableTypeSelectOptions}
            setSelectedCarId={setSelectedCarId}
          />,
        ],
        consumableType === ConsumableType.FINES_PAYMENT,
      )}

      {renderIf(
        () => [
          <ParkPaymentForm
            defaultValues={selectedCarId ? { carId: selectedCarId } : undefined}
            parkPayment={consumable as ParkPaymentEntity}
            onClose={(options) =>
              onClose({ ...options, consumable: options?.parkPayment || undefined })
            }
            closeButtonText={closeButtonText}
            selectedConsumableType={consumableType}
            setSelectedConsumableType={setConsumableType}
            consumableTypeSelectOptions={ConsumableTypeSelectOptions}
            setSelectedCarId={setSelectedCarId}
          />,
        ],
        consumableType === ConsumableType.PARK_PAYMENT,
      )}

      {renderIf(
        () => [
          <OtherConsumableForm
            defaultValues={selectedCarId ? { carId: selectedCarId } : undefined}
            otherConsumable={consumable as OtherConsumableEntity}
            onClose={(options) =>
              onClose({ ...options, consumable: options?.otherConsumable || undefined })
            }
            closeButtonText={closeButtonText}
            selectedConsumableType={consumableType}
            setSelectedConsumableType={setConsumableType}
            consumableTypeSelectOptions={ConsumableTypeSelectOptions}
            setSelectedCarId={setSelectedCarId}
          />,
        ],
        consumableType === ConsumableType.OTHER,
      )}
    </>
  );
};
